import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="Page not found."
           fullTitle={true}
           description="The page you are looking for has been removed or relocated."/>
      <h1>The page you are looking for has been removed or relocated.</h1>
    </Layout>
  );
};

export default NotFoundPage;
